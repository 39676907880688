import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { withTheme } from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

import { PageSection } from './page';



const PrivacyPolicy = (props) => {
  return (<StaticQuery
    query={graphql`
      query PrivacyPolicyQuery {        
        markdownRemark(frontmatter: { page: { eq: "privacy-policy" } }) {
          html
          frontmatter {
            locale              
          }
        }
      }
    `}
    render={data => {
      const { html } = data.markdownRemark;

      return <PageSection>
        <Container>
          <Row>
            <Col dangerouslySetInnerHTML={{ __html: html }} />
          </Row> 
        </Container>
      </PageSection>
    }}
  />) 
}

export default withTheme(PrivacyPolicy)